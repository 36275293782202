import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PostSimpleImageElement = props => (
  <StaticQuery
    query={graphql`
          query BlogPostSimpleImageQuery {
            source: allFile(
              filter: { 
                absolutePath: { regex: "/blog/" } 
                childImageSharp: { 
                  internal: {
                    type: { eq: "ImageSharp" }
                  }
                }
              }
            ) {
              nodes {
                name
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: NONE)
                }
              }
            }
          }
        `}
    render={data => {

      const image = data.source.nodes.find(node => {
        return node.name.includes(props.image);
      });
      if (!image) {
        return null;
      }

      return <GatsbyImage alt={props.alt} image={getImage(image)} />
    }}
  />
);


class PostSimpleImage extends React.Component {

  render() {
    return (
      <div className={`post-simple-image`} ref={c => this.postImageWrapper = c}>
        <PostSimpleImageElement alt={this.props.alt || ""} image={this.props.image} />
      </div>
    )
  }
}



export default PostSimpleImage
