import React from "react"
import { graphql } from "gatsby"
import LayoutSecondary from "../components/layout-secondary"
import { Container, Row, Col, Media } from "react-bootstrap"
import Seo from "../components/seo"
import HeroPost from "../components/heroes/hero-post";
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import Avatar from "../components/misc/avatar"
import ImageFluid from "../components/misc/image-fluid"
import InfoBlock from "../components/misc/info-block"
import PostImage from "../components/misc/post-image"
import PostSimpleImage from "../components/misc/post-simple-image"
import PostModalImage from "../components/misc/post-modal-image"
import SimpleMailingList from "../components/ctas/simple-mailing-list"
import { getSrc } from "gatsby-plugin-image"

const shortcodes = {
  ImageFluid,
  InfoBlock,
  PostImage,
  PostSimpleImage,
  PostModalImage
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;
    const location = this.props.location;
    return (
      <LayoutSecondary>
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          ogImage={getSrc(post.frontmatter.image)}
          pathname = {location.pathname}
          ogType={`blog`}
        />
        <HeroPost
            theme={`secondary`}
            content={post}
        />
        <Container>
          <div className={`top-post-overflow-wrapper`}>
            <div className={`post-header`}>
              <Row>
                <Col md={1}></Col>
                <Col md={8}>
                  <div className={`back`}>
                    <span>
                      <Link className={`link`} to={`/blog`}>{this.props.intl.formatMessage({id : "pages.blog.title"})}</Link>
                      {` /`}
                    </span>
                  </div>
                  <h1>{post.frontmatter.title}</h1>
                  <Media>
                    <div className={`avatar`}>
                      <Avatar alt={post.frontmatter.author} filename={`authors/` + post.frontmatter.author + `.png`} />
                    </div>
                    <Media.Body>
                      <div className={`author`}>{post.frontmatter.author}</div>
                      <div className={`date`}>{post.frontmatter.date}</div>
                      <div className={`tags`}>{this.props.intl.formatMessage({id : "pages.blog.postTags"})} {this.props.intl.formatMessage({id : "pages.blog.tag-" + post.frontmatter.tags})}</div>
                    </Media.Body>
                  </Media>
                  <p className={`text`}>{post.frontmatter.excerpt}</p>
                  <div className={`image`}>
                    <ImageFluid alt={post.frontmatter.title} filename={post.fields.cover} />
                  </div>
                </Col>
                <Col md={3}></Col>
              </Row>
            </div>
            <div className={`post-html`}>
              <Row>
                <Col md={1}></Col>
                <Col md={8}>
                  <MDXProvider components={shortcodes}>
                    <MDXRenderer>{post.body}</MDXRenderer>
                  </MDXProvider>
                </Col>
                <Col md={3}></Col>
              </Row>
            </div>
          </div>
        </Container>
        <SimpleMailingList />
      </LayoutSecondary>
    )
  }
}

export default injectIntl(BlogPostTemplate)

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      fields {
        slug
        publishDate
        cover
      }
      frontmatter {
        date(formatString: "MMM D YYYY")
        title
        lang
        author
        excerpt
        description
        tags
        image {
          childImageSharp {
            gatsbyImageData(layout: FIXED, height: 315, width: 600, placeholder: NONE)
          }
        }
      }
    }
  }
`
