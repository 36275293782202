import React from "react"

const InfoBlock = ({ children }) => {

  return (
    <div className={`info-block`}>
      {children}
    </div>
  )
}


export default InfoBlock
