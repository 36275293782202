import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"

const HeroPost = ({ intl, theme, content }) => (
    <div className={`hero-post ` + theme}>
        <div className={`clipped-area`}></div>
        <div className={`non-clipped-area`}>
        </div>
    </div>
)

export default injectIntl(HeroPost)
