import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PostImageElement = props => (
  <StaticQuery
    query={graphql`
          query BlogPostImageQuery {
            source: allFile(
              filter: { 
                absolutePath: { regex: "/blog/" }, 
                childImageSharp: { 
                  internal: {
                    type: { eq: "ImageSharp" }
                  }
                }
              }
            ) {
              nodes {
                name
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: NONE)
                }
              }
            }
          }
        `}
    render={data => {

      const image = data.source.nodes.find(node => {
        return node.name.includes(props.image);
      });
      if (!image) {
        return null;
      }

      return <GatsbyImage alt={props.alt} image={getImage(image)}  onLoad={() => {props.handleImageLoad();}} />
    }}
  />
);


class PostImage extends React.Component {

  componentDidMount(){
    window.addEventListener('resize', this.handleImageLoad);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleImageLoad);
  }

  handleImageLoad = () => {
    window.setTimeout(() => {
      if (this.postImageWrapper) {
        let el = this.postImageWrapper;
        el.querySelector(".circle").style.width = (el.offsetHeight + 60) + 'px';
      }
    }, 1000)
  }

  render() {
    return (
      <div className={`post-image`} ref={c => this.postImageWrapper = c}>
        <div className={`circle ` + this.props.orientation}></div>
        <PostImageElement alt={this.props.alt || ""} image={this.props.image} handleImageLoad={this.handleImageLoad} />
      </div>
    )
  }
}



export default PostImage
