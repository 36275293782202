import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { Modal } from "react-bootstrap"

const PostModalImageElement = props => (
  <StaticQuery
    query={graphql`
          query PostModalImageQuery {
            source: allFile(
              filter: { 
                absolutePath: { regex: "/blog/" } 
                childImageSharp: { 
                  internal: {
                    type: { eq: "ImageSharp" }
                  }
                }
              }
            ) {
              nodes {
                name
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: NONE)              
                }
              }
            }
          }
        `}
    render={data => {

      const image = data.source.nodes.find(node => {
        return node.name.includes(props.image);
      });
      if (!image) {
        return null;
      }

      return <button className={`post-modal-button`} onClick={() => { props.onImageClicked(getSrc(image)); }}><GatsbyImage alt={props.alt} image={getImage(image)} /></button>
    }}
  />
);


class PostModalImage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      modalImage: null,
      showModal: false
    }
  }

  handleOpenModal = (image) => {
    this.setState({
      modalImage: image,
      showModal: true
    });
  }

  closeModal = () => {
    this.setState({
      modalImage: null,
      showModal: false
    });
  }

  render() {
    return (
      <div className={`post-modal-image`}>

          <PostModalImageElement alt={this.props.alt || ""} image={this.props.image} onImageClicked={this.handleOpenModal} />
          <Modal centered dialogClassName={`original-image-modal`} show={this.state.showModal} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>

              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img className={`original-image`} src={this.state.modalImage} alt={this.props.alt || ""} />
            </Modal.Body>
          </Modal>
      </div>
    )
  }
}



export default PostModalImage
